<template>
    <div class="permission-templates">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Permission Templates</a></li>
            </ul>
        </nav>

        <div class="field is-grouped">
            <h1 class="title">Permission Templates</h1>

            <p class="control mt-1 ml-3">
                <router-link class="button is-primary is-small is-rounded p-3" to="permissionTemplate" :disabled="isLoading">
                    <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                </router-link>
            </p>
        </div>

        <Loading v-if="isLoading" />
        <div v-else class="table-container">
            <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(template, index) in templates" :key="'template' + index">
                        <td> {{ template.id }}</td>
                        <td>
                            <router-link :to="'/permissionTemplate/' + template.id">{{ template.name }}</router-link>
                        </td>
                        <td>
                            <button class="button is-white is-inverted is-small" @click="veryifyDeleteTemplate(template)">
                                <font-awesome-icon :icon="['fa', 'trash-alt']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { secureGet, secureDelete } from '../secureFetch.js';

    import Loading from '../components/Loading';

    export default {
        name: "PermissionTemplates",
        components: {
        Loading
        },
        data() {
            return {
                isLoading: true,
                templates: [],
            }
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            }
        },
        mounted() {
            this.getPermissionTemplates();
        },
        methods: {
            getPermissionTemplates() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/permissiontemplate/all-permission-templates")
                    .then(data => {
                        if (data && data.success) {
                            this.templates = data.permissionTemplates;
                            this.isLoading = false;
                        }
                    });
            },
            veryifyDeleteTemplate(template) {
                if (confirm("Really delete " + template.name + "?")) {
                    secureDelete(this.hostUrl + "v1/permissiontemplate/" + template.id)
                        .then(data => {
                            this.isLoading = false;

                            if (data && data.success) {
                                this.getPermissionTemplates();
                            }
                        });
                }
            }
        }
    };
</script>

<style scoped>
</style>
